body {
	color: $text;
	font-size: $bodyfontsize;
	font-family: $bodyfont;
}

.body_overflow {
	overflow: hidden;
}

.main_menu {
	margin-left: auto;

	ul {
		display: flex;
		align-items: center;

		li {
			list-style: none;
			margin-right: 4.8em;
			@include tablet-md-1024 {
				margin-right: 20px;
			}
			@include mobile-md-640 {
				margin-right: 0;
				margin-bottom: 20px;
			}

			a {
				font-size: 1.5rem;
				color: rgb(0, 0, 0);
				transition: .3s;
				font-weight: bold;
				@include tablet-md-1024 {
					font-size: 25px;
				}

				&:hover {
					opacity: .7;
				}
			}
		}
	}
}

.section_top {
	padding-top: 6%;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	.content_box {
		@include desktop-md {
			min-width: 40%;
		}
		@include tablet-md-1024 {
			min-width: 100%;
		}
	}

	.position {
		margin-top: 1rem;
		margin-left: 3.4rem;
		@include tablet-md-1024 {
			margin: 0;
		}

		&.two_image {
			position: relative;
		}
	}

	p {
		margin-bottom: 2.57em;
	}
}

.section_2 {
	padding-top: 10%;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	.image {
		@include tablet-sm-991 {
			box-shadow: 0px 25px 90px 0px rgba(88, 166, 205, 0.56);
			border-radius: 1rem;
		}
	}

	p {
		margin-bottom: 3.57em;
		@include tablet-md-1024 {
			margin-bottom: 0;
		}
	}
}

.section_3 {
	padding-top: 7%;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	p {
		margin-bottom: 2.57em;
		@include tablet-md-1024 {
			margin-bottom: 0;
		}
	}
}

.section_4 {
	padding-top: 13%;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	.image {
		&.position {
			&.left {
				margin-left: 2.8rem;
				@include tablet-md-1024 {
					margin: 0;
				}
			}
		}
	}

	.content_box {
		&.position {
			&.left {
				margin-left: 7.8rem;
				@include tablet-md-1024 {
					margin: 0;
				}
			}
		}
	}
}

.section_5 {
	padding-top: 21.7%;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	.title_h2 {
		max-width: 30rem;
	}

	.image {
		&.position {
			&.left {
				margin-left: 8.3rem;
				@include tablet-md-1024 {
					margin: 0;
				}
			}
		}
	}
}

.section_6 {
	padding-top: 21.4rem;
	@include tablet-md-1024 {
		padding-top: 30px;
	}

	.image {
		&.position {
			&.left {
				margin-left: 2rem;
				@include tablet-md-1024 {
					margin: 0;
				}
			}
		}

		.image_s6_2 {
			position: absolute;
			right: 4%;
			bottom: -14%;
			z-index: -1;
			@include tablet-md-1024 {
				display: none;
			}
		}
	}

	.content_box {
		&.position {
			&.left {
				margin-left: 8rem;
				@include tablet-md-1024 {
					margin: 0;
				}
			}
		}

		p {
			max-width: 47rem;
		}
	}
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	background: rgba(19, 100, 112, .8);
	z-index: 99;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	padding: 4rem 0;
}

.popup {
	max-width: 70rem;
	width: calc(100% - 30px);
	background: #fff;
	position: relative;
	overflow: hidden;
	padding: 3.125rem 1.875rem 1.875rem 1.875rem;
	margin: auto 0;
	@media (max-width: 768px) {
		padding: 50px 15px 15px 15px;
	}

	p {
		max-width: none;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.body_overflow {
	overflow: hidden;
}

.close_popup {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	transition: .3s;

	&:hover {
		opacity: .7;
	}
}
