@import 'inc/default';
@import 'inc/fonts';
@import 'inc/vars';

@import 'layout';

@import 'parts/header';

@media only screen and (min-width: 1025px) {
	@import '../libs/aos/aos';
}

@import 'general';

@import 'parts/footer';

