* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  min-width: 320px;
  position: relative;
}

article,
aside,
details,
figure,
figcaption,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.hidden,
[hidden],
template {
  display: none;
}

a {
  text-decoration: none;
  background: transparent;
}

a:active, a:hover {
  outline: 0;
}

a img {
  border: 0;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 75%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  border: 0;
  font: inherit;
  color: inherit;
  appearance: none;
  border-radius: 0;
  text-transform: none;
  box-sizing: border-box;
  outline: none !important;
  background-clip: padding-box;
  background-color: transparent;
}

button,
html input[type='button'] {
  cursor: pointer;
  appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='reset'], input[type='submit'] {
  cursor: pointer;
  appearance: button;
}

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: border-box;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
  appearance: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
  border: 0;
}

.clearfix {
  clear: both;
}

@keyframes float-vertically {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes float-vertically-10 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes float-horizontally {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes float-square {
  0% {
    transform: translate(0, -10px);
  }
  20% {
    transform: translate(-10px, 0);
  }
  40% {
    transform: translate(0, 10px);
  }
  80% {
    transform: translate(10px, 0);
  }
  100% {
    transform: translate(0, -10px);
  }
}

@media (min-width: 1024px) and (max-width: 1920px) {
  html {
    font-size: .83vw;
  }
}

body {
  background: #feffff;
}

.wrap_main {
  overflow: hidden;
  position: relative;
}

.wrap_main:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../img/gradient.png") no-repeat;
  pointer-events: none;
  z-index: -1;
  background-size: cover;
}

@media (max-width: 1024px) {
  section {
    margin-bottom: 50px;
  }
}

.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1600px) {
  .container {
    padding-left: 160px;
    padding-right: 160px;
  }
}

@media (max-width: 1600px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 90vw;
  }
}

@media (max-width: 1600px) {
  .container.d-flex {
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .container.d-flex {
    flex-direction: column;
  }
  .container.d-flex .image_box {
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  section:nth-child(even) .d-flex {
    flex-direction: column-reverse;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.8125rem;
  margin-right: -0.8125rem;
}

.row [class*='col-'] {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
}

.row.no_gutter {
  margin-left: 0;
  margin-right: 0;
}

.row.no_gutter [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.col-6 {
  width: 50%;
}

@media (max-width: 1024px) {
  .col-6 {
    width: 100%;
  }
}

.col-4 {
  width: 33.3333333%;
}

@media (max-width: 1024px) {
  .col-4 {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .col-4 {
    width: 100%;
  }
}

.col-3 {
  width: 25%;
}

@media (max-width: 1024px) {
  .col-3 {
    width: 33.33333%;
  }
}

@media (max-width: 1024px) {
  .col-3 {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .col-3 {
    width: 100%;
  }
}

.btn {
  border-radius: 1.5625em;
  color: white;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 2.1em;
  background: #46cefd;
  background: linear-gradient(90deg, #46cefd 0%, #04cec3 100%);
  font-size: 1.5rem;
  transition: .3s;
  white-space: nowrap;
}

.btn:hover {
  opacity: .7 !important;
}

@media (max-width: 1024px) {
  .btn {
    padding: .5em 1.1em;
  }
}

.btn.btn_big {
  padding: .8em 3.8em;
}

@media (max-width: 640px) {
  .btn.btn_big {
    padding: 14px 24px;
  }
}

.title_h1 {
  font-size: 4.59rem;
  color: black;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: .1em;
}

@media (max-width: 1024px) {
  .title_h1 {
    font-size: 3rem;
    margin-bottom: 0.4em;
  }
}

@media (max-width: 640px) {
  .title_h1 {
    font-size: 40px;
  }
}

.title_h2 {
  font-size: 2.53rem;
  color: black;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: .9em;
}

@media (max-width: 1024px) {
  .title_h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 640px) {
  .title_h2 {
    font-size: 28px;
  }
}

p {
  font-size: 1.56rem;
  color: #919191;
  line-height: 1.5;
  max-width: 44rem;
}

@media (max-width: 1024px) {
  p {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

@media (max-width: 640px) {
  p {
    font-size: 19px;
  }
}

.shadow {
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0px 25px 90px 0px rgba(88, 166, 205, 0.56);
}

.image {
  position: relative;
}

.image img {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.image .image_s4_2 {
  position: absolute;
  right: -6%;
  bottom: -10%;
  z-index: -1;
}

@media (max-width: 1024px) {
  .image .image_s4_2 {
    display: none;
  }
}

.d-flex {
  display: flex;
}

.d-flex.center_v {
  align-items: center;
}

.image_box {
  position: relative;
}

@media (max-width: 1600px) {
  .image_box {
    width: 50%;
  }
}

.image_box .position.left {
  margin-left: 8rem;
}

@media (max-width: 1024px) {
  .image_box .position.left {
    margin: 0;
  }
}

.image_chips {
  position: absolute;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .image_chips {
    display: none;
  }
}

.image_chips.chips_s1_1 {
  bottom: -4%;
  left: -9%;
  z-index: -1;
  width: 18%;
  height: auto;
  animation: float-square 12s ease-in-out infinite alternate;
}

.image_chips.chips_s1_2 {
  top: 14%;
  right: -5%;
  z-index: -1;
  width: 7%;
  height: auto;
  animation: float-vertically-10 4s ease-in-out infinite;
}

.image_chips.chips_s2_1 {
  top: 41%;
  left: -2%;
  width: 9%;
  height: auto;
  animation: float-vertically-10 4s ease-in-out infinite;
}

.image_chips.chips_s2_2 {
  bottom: 6%;
  left: 35%;
  width: 8%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s2_3 {
  bottom: 14%;
  right: 2%;
  width: 12%;
  height: auto;
  animation: float-square 12s ease-in-out infinite alternate;
}

.image_chips.chips_s2_4 {
  top: 1%;
  right: -2%;
  width: 7%;
  height: auto;
  animation: float-horizontally 6s ease-in-out infinite;
}

.image_chips.chips_s2_5 {
  bottom: 61%;
  left: -8%;
  width: 6%;
  height: auto;
  animation: float-square 12s ease-in-out infinite alternate;
}

.image_chips.chips_s2_6 {
  top: -50%;
  right: -2%;
  width: 14%;
  height: auto;
  animation: float-vertically-10 4s ease-in-out infinite;
}

.image_chips.chips_s3_1 {
  top: -20%;
  left: -41%;
  animation: float-vertically-10 4s ease-in-out infinite;
  z-index: -1;
  width: 147%;
  height: auto;
  max-width: none;
}

.image_chips.chips_s3_2 {
  top: -17%;
  right: 24%;
  width: 11%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s4_1 {
  top: -14%;
  left: 8%;
  z-index: -1;
  width: 19%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s4_2 {
  bottom: -20%;
  left: 46%;
  width: 2%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s4_3 {
  top: -8%;
  left: 11%;
  width: 2%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s4_4 {
  top: 5%;
  right: -1%;
  width: 5%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s4_5 {
  bottom: -82%;
  right: 26%;
  width: 46%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s5_1 {
  bottom: 57%;
  right: 41%;
  width: 5%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s5_2 {
  bottom: -35%;
  right: 6%;
  width: 7%;
  height: auto;
  animation: float-vertically-10 4s ease-in-out infinite;
}

.image_chips.chips_s5_3 {
  bottom: 65%;
  right: -5%;
  width: 17%;
  height: auto;
  animation: float-square 12s ease-in-out infinite alternate;
}

.image_chips.chips_s5_4 {
  top: -27%;
  right: 18%;
  width: 55%;
  height: auto;
  z-index: -1;
  animation: float-horizontally 6s ease-in-out infinite;
}

.image_chips.chips_s6_1 {
  top: -37%;
  right: 3%;
  width: 90%;
  height: auto;
  z-index: -1;
  animation: float-square 12s ease-in-out infinite alternate;
}

.image_chips.chips_s6_2 {
  top: -44%;
  left: 13%;
  width: 10%;
  height: auto;
  animation: float-horizontally 6s ease-in-out infinite;
}

.image_chips.chips_s6_3 {
  bottom: -35%;
  right: 10%;
  width: 9%;
  height: auto;
  animation: float-vertically-10 4s ease-in-out infinite;
}

.image_chips.chips_s6_4 {
  top: -30%;
  right: -1%;
  width: 12%;
  height: auto;
  animation: float-vertically 6s ease-in-out infinite;
}

.image_chips.chips_s6_5 {
  bottom: -60%;
  left: -2%;
  width: 58%;
  height: auto;
  animation: float-horizontally 6s ease-in-out infinite;
}

.content_box {
  position: relative;
}

@media (max-width: 1024px) {
  .content_box {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .content_box {
    width: 100%;
  }
}

.content_box.position.left {
  margin-left: 6rem;
}

@media (max-width: 1024px) {
  .content_box.position.left {
    margin: 0;
  }
}

@media (min-width: 1025px) {
  section:not(.section_top) .title_h2 {
    font-size: 2.4rem;
  }
}

@media (min-width: 1025px) and (max-width: 640px) {
  section:not(.section_top) .title_h2 {
    font-size: 28px;
  }
}

.social_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.social_box li {
  margin-right: 3.4rem;
  list-style: none;
}

@media (max-width: 1024px) {
  .social_box li {
    margin: 10px;
  }
}

.social_box li:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .social_box li:last-child {
    margin-right: 10px;
  }
}

.social_box li a {
  width: 100%;
  height: auto;
  display: flex;
  transition: .3s;
  max-width: 2.7rem;
}

.social_box li a img {
  width: 100%;
}

@media (max-width: 1024px) {
  .social_box li a {
    width: 40px;
    height: 40px;
  }
}

.social_box li a:hover {
  opacity: .7;
}

header {
  padding: 20px 0;
}

@media (max-width: 640px) {
  header .btn {
    display: none;
  }
}

header .logo img {
  width: 100%;
  max-width: 50%;
}

header .wrap_top_line {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  header .wrap_top_line {
    justify-content: space-between;
  }
}

header .wrap_top_line nav {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  header .wrap_top_line nav {
    transform: translateX(-100%);
    position: absolute;
    transition: .3s;
    background: #fff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  header .wrap_top_line nav ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

header .wrap_top_line nav.active {
  transform: translateX(0);
}

.burger_menu {
  display: none;
}

.burger_menu img {
  display: flex;
  width: 30px;
}

@media (max-width: 640px) {
  .burger_menu {
    display: block;
  }
}

.close_menu {
  display: none;
}

.close_menu img {
  display: flex;
  width: 40px;
}

@media (max-width: 640px) {
  .close_menu {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  [data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
    transition-duration: 50ms;
  }
  [data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
    transition-delay: 50ms;
  }
  [data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
    transition-duration: .1s;
  }
  [data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
  }
  [data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
    transition-duration: .15s;
  }
  [data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
    transition-delay: .15s;
  }
  [data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
    transition-duration: .2s;
  }
  [data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
  }
  [data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
    transition-duration: .25s;
  }
  [data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
    transition-delay: .25s;
  }
  [data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
    transition-duration: .3s;
  }
  [data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
    transition-delay: .3s;
  }
  [data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
    transition-duration: .35s;
  }
  [data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
    transition-delay: .35s;
  }
  [data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s;
  }
  [data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
    transition-delay: .4s;
  }
  [data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
    transition-duration: .45s;
  }
  [data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
    transition-delay: .45s;
  }
  [data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s;
  }
  [data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
    transition-delay: .5s;
  }
  [data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
    transition-duration: .55s;
  }
  [data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
    transition-delay: .55s;
  }
  [data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
    transition-duration: .6s;
  }
  [data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
    transition-delay: .6s;
  }
  [data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
    transition-duration: .65s;
  }
  [data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
    transition-delay: .65s;
  }
  [data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
    transition-duration: .7s;
  }
  [data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
    transition-delay: .7s;
  }
  [data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
    transition-duration: .75s;
  }
  [data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
    transition-delay: .75s;
  }
  [data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
    transition-duration: .8s;
  }
  [data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
    transition-delay: .8s;
  }
  [data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
    transition-duration: .85s;
  }
  [data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
    transition-delay: .85s;
  }
  [data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
    transition-duration: .9s;
  }
  [data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
    transition-delay: .9s;
  }
  [data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
    transition-duration: .95s;
  }
  [data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
    transition-delay: .95s;
  }
  [data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s;
  }
  [data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
    transition-delay: 1s;
  }
  [data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
    transition-duration: 1.05s;
  }
  [data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
    transition-delay: 1.05s;
  }
  [data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
    transition-duration: 1.1s;
  }
  [data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
    transition-delay: 1.1s;
  }
  [data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
    transition-duration: 1.15s;
  }
  [data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
    transition-delay: 1.15s;
  }
  [data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
    transition-duration: 1.2s;
  }
  [data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
    transition-delay: 1.2s;
  }
  [data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
    transition-duration: 1.25s;
  }
  [data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
    transition-delay: 1.25s;
  }
  [data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
    transition-duration: 1.3s;
  }
  [data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
    transition-delay: 1.3s;
  }
  [data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
    transition-duration: 1.35s;
  }
  [data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
    transition-delay: 1.35s;
  }
  [data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
    transition-duration: 1.4s;
  }
  [data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
    transition-delay: 1.4s;
  }
  [data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
    transition-duration: 1.45s;
  }
  [data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
    transition-delay: 1.45s;
  }
  [data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
    transition-duration: 1.5s;
  }
  [data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
    transition-delay: 1.5s;
  }
  [data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
    transition-duration: 1.55s;
  }
  [data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
    transition-delay: 1.55s;
  }
  [data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
    transition-duration: 1.6s;
  }
  [data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
    transition-delay: 1.6s;
  }
  [data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
    transition-duration: 1.65s;
  }
  [data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
    transition-delay: 1.65s;
  }
  [data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
    transition-duration: 1.7s;
  }
  [data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
    transition-delay: 1.7s;
  }
  [data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
    transition-duration: 1.75s;
  }
  [data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
    transition-delay: 1.75s;
  }
  [data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
    transition-duration: 1.8s;
  }
  [data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
    transition-delay: 1.8s;
  }
  [data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
    transition-duration: 1.85s;
  }
  [data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
    transition-delay: 1.85s;
  }
  [data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
    transition-duration: 1.9s;
  }
  [data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
    transition-delay: 1.9s;
  }
  [data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
    transition-duration: 1.95s;
  }
  [data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
    transition-delay: 1.95s;
  }
  [data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
    transition-duration: 2s;
  }
  [data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
    transition-delay: 2s;
  }
  [data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
    transition-duration: 2.05s;
  }
  [data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
    transition-delay: 2.05s;
  }
  [data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
    transition-duration: 2.1s;
  }
  [data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
    transition-delay: 2.1s;
  }
  [data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
    transition-duration: 2.15s;
  }
  [data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
    transition-delay: 2.15s;
  }
  [data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
    transition-duration: 2.2s;
  }
  [data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
    transition-delay: 2.2s;
  }
  [data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
    transition-duration: 2.25s;
  }
  [data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
    transition-delay: 2.25s;
  }
  [data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
    transition-duration: 2.3s;
  }
  [data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
    transition-delay: 2.3s;
  }
  [data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
    transition-duration: 2.35s;
  }
  [data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
    transition-delay: 2.35s;
  }
  [data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
    transition-duration: 2.4s;
  }
  [data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
    transition-delay: 2.4s;
  }
  [data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
    transition-duration: 2.45s;
  }
  [data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
    transition-delay: 2.45s;
  }
  [data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
    transition-duration: 2.5s;
  }
  [data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
    transition-delay: 2.5s;
  }
  [data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
    transition-duration: 2.55s;
  }
  [data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
    transition-delay: 2.55s;
  }
  [data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
    transition-duration: 2.6s;
  }
  [data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
    transition-delay: 2.6s;
  }
  [data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
    transition-duration: 2.65s;
  }
  [data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
    transition-delay: 2.65s;
  }
  [data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
    transition-duration: 2.7s;
  }
  [data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
    transition-delay: 2.7s;
  }
  [data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
    transition-duration: 2.75s;
  }
  [data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
    transition-delay: 2.75s;
  }
  [data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
    transition-duration: 2.8s;
  }
  [data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
    transition-delay: 2.8s;
  }
  [data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
    transition-duration: 2.85s;
  }
  [data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
    transition-delay: 2.85s;
  }
  [data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
    transition-duration: 2.9s;
  }
  [data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
    transition-delay: 2.9s;
  }
  [data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
    transition-duration: 2.95s;
  }
  [data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
    transition-delay: 2.95s;
  }
  [data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
    transition-duration: 3s;
  }
  [data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
    transition-delay: 3s;
  }
  [data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }
  [data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease;
  }
  [data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
    transition-timing-function: ease-in;
  }
  [data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
    transition-timing-function: ease-out;
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
    transition-timing-function: ease-in-out;
  }
  [data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  [data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  [data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity,transform;
  }
  [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0);
  }
  [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }
  [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }
  [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }
  [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }
  [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }
  [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }
  [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }
  [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }
  [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity,transform;
  }
  [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }
  [data-aos=zoom-in] {
    transform: scale(0.6);
  }
  [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  [data-aos=zoom-out] {
    transform: scale(1.2);
  }
  [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
  }
  [data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translateZ(0);
  }
  [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }
  [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }
  [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }
  [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }
  [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }
  [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }
  [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}

body {
  color: #000;
  font-size: 16px;
  font-family: sans-serif;
}

.body_overflow {
  overflow: hidden;
}

.main_menu {
  margin-left: auto;
}

.main_menu ul {
  display: flex;
  align-items: center;
}

.main_menu ul li {
  list-style: none;
  margin-right: 4.8em;
}

@media (max-width: 1024px) {
  .main_menu ul li {
    margin-right: 20px;
  }
}

@media (max-width: 640px) {
  .main_menu ul li {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.main_menu ul li a {
  font-size: 1.5rem;
  color: black;
  transition: .3s;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .main_menu ul li a {
    font-size: 25px;
  }
}

.main_menu ul li a:hover {
  opacity: .7;
}

.section_top {
  padding-top: 6%;
}

@media (max-width: 1024px) {
  .section_top {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section_top .content_box {
    min-width: 40%;
  }
}

@media (max-width: 1024px) {
  .section_top .content_box {
    min-width: 100%;
  }
}

.section_top .position {
  margin-top: 1rem;
  margin-left: 3.4rem;
}

@media (max-width: 1024px) {
  .section_top .position {
    margin: 0;
  }
}

.section_top .position.two_image {
  position: relative;
}

.section_top p {
  margin-bottom: 2.57em;
}

.section_2 {
  padding-top: 10%;
}

@media (max-width: 1024px) {
  .section_2 {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .section_2 .image {
    box-shadow: 0px 25px 90px 0px rgba(88, 166, 205, 0.56);
    border-radius: 1rem;
  }
}

.section_2 p {
  margin-bottom: 3.57em;
}

@media (max-width: 1024px) {
  .section_2 p {
    margin-bottom: 0;
  }
}

.section_3 {
  padding-top: 7%;
}

@media (max-width: 1024px) {
  .section_3 {
    padding-top: 30px;
  }
}

.section_3 p {
  margin-bottom: 2.57em;
}

@media (max-width: 1024px) {
  .section_3 p {
    margin-bottom: 0;
  }
}

.section_4 {
  padding-top: 13%;
}

@media (max-width: 1024px) {
  .section_4 {
    padding-top: 30px;
  }
}

.section_4 .image.position.left {
  margin-left: 2.8rem;
}

@media (max-width: 1024px) {
  .section_4 .image.position.left {
    margin: 0;
  }
}

.section_4 .content_box.position.left {
  margin-left: 7.8rem;
}

@media (max-width: 1024px) {
  .section_4 .content_box.position.left {
    margin: 0;
  }
}

.section_5 {
  padding-top: 21.7%;
}

@media (max-width: 1024px) {
  .section_5 {
    padding-top: 30px;
  }
}

.section_5 .title_h2 {
  max-width: 30rem;
}

.section_5 .image.position.left {
  margin-left: 8.3rem;
}

@media (max-width: 1024px) {
  .section_5 .image.position.left {
    margin: 0;
  }
}

.section_6 {
  padding-top: 21.4rem;
}

@media (max-width: 1024px) {
  .section_6 {
    padding-top: 30px;
  }
}

.section_6 .image.position.left {
  margin-left: 2rem;
}

@media (max-width: 1024px) {
  .section_6 .image.position.left {
    margin: 0;
  }
}

.section_6 .image .image_s6_2 {
  position: absolute;
  right: 4%;
  bottom: -14%;
  z-index: -1;
}

@media (max-width: 1024px) {
  .section_6 .image .image_s6_2 {
    display: none;
  }
}

.section_6 .content_box.position.left {
  margin-left: 8rem;
}

@media (max-width: 1024px) {
  .section_6 .content_box.position.left {
    margin: 0;
  }
}

.section_6 .content_box p {
  max-width: 47rem;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background: rgba(19, 100, 112, 0.8);
  z-index: 99;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}

.popup {
  max-width: 70rem;
  width: calc(100% - 30px);
  background: #fff;
  position: relative;
  overflow: hidden;
  padding: 3.125rem 1.875rem 1.875rem 1.875rem;
  margin: auto 0;
}

@media (max-width: 768px) {
  .popup {
    padding: 50px 15px 15px 15px;
  }
}

.popup p {
  max-width: none;
  margin-bottom: 1rem;
}

.popup p:last-child {
  margin-bottom: 0;
}

.body_overflow {
  overflow: hidden;
}

.close_popup {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: .3s;
}

.close_popup:hover {
  opacity: .7;
}

footer {
  margin-top: 15.5%;
  margin-bottom: 6%;
}

@media (max-width: 768px) {
  footer {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

footer .wrap_box {
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 0.659);
  box-shadow: 0px 11px 43px 0px rgba(88, 166, 205, 0.21);
  background-image: url("../img/bg_footer.png");
  background-size: 114%;
  min-height: 22.625rem;
  background-position: left -77px top 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3%;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  footer .wrap_box {
    background-position: center bottom 0;
    background-size: 130%;
  }
}

@media (max-width: 768px) {
  footer .wrap_box {
    padding: 20px;
    margin-bottom: 30px;
  }
}

footer .wrap_box p {
  margin: 2rem 0;
  color: #000;
}

@media (max-width: 768px) {
  footer .wrap_box p {
    margin: 25px 0;
  }
}

footer .copyright {
  font-size: 1.125rem;
  color: #8a8a8a;
  text-align: center;
}

@media (max-width: 768px) {
  footer .copyright {
    font-size: 18px;
    white-space: nowrap;
  }
}

footer .copyright .version {
  margin-left: 2.5em;
}

@media (max-width: 768px) {
  footer .copyright .version {
    margin-left: 30px;
  }
}
