footer {
	margin-top: 15.5%;
	margin-bottom: 6%;
	@include tablet-sm-768 {
		margin-top: 50px;
		margin-bottom: 30px;
	}

	.wrap_box {
		border-radius: 0.625rem;
		background-color: rgba(255, 255, 255, 0.659);
		box-shadow: 0px 11px 43px 0px rgba(88, 166, 205, 0.21);
		background-image: url("../img/bg_footer.png");
		background-size: 114%;
		min-height: 22.625rem;
		background-position: left -77px top 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		margin-bottom: 3%;
		background-repeat: no-repeat;
		@include tablet-md-1024 {
			background-position: center bottom 0;
			background-size: 130%;
		}
		@include tablet-sm-768 {
			padding: 20px;
			margin-bottom: 30px;
		}

		p {
			margin: 2rem 0;
			@include tablet-sm-768 {
				margin: 25px 0;
			}
			color: #000;
		}
	}

	.copyright {
		font-size: 1.125rem;
		color: rgb(138, 138, 138);
		text-align: center;
		@include tablet-sm-768 {
			font-size: 18px;
			white-space: nowrap;
		}

		.version {
			margin-left: 2.5em;
			@include tablet-sm-768 {
				margin-left: 30px;
			}
		}
	}
}
