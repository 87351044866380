header {
	padding: 20px 0;
	@include mobile-md-640 {
		.btn {
			display: none;
		}
	}

	.logo {
		img {
			width: 100%;
			max-width: 50%;
		}
	}

	.wrap_top_line {
		display: flex;
		align-items: center;
		@include mobile-md-640 {
			justify-content: space-between;
		}

		nav {
			display: flex;
			align-items: center;
			@include mobile-md-640 {
				transform: translateX(-100%);
				position: absolute;
				transition: .3s;
				background: #fff;
				left: 0;
				top: 0;
				width: 100%;
				height: 100vh;
				z-index: 99;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				ul {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}

			&.active {
				transform: translateX(0);
			}
		}
	}
}

.burger_menu {
	display: none;

	img {
		display: flex;
		width: 30px;
	}

	@include mobile-md-640 {
		display: block;
	}
}


.close_menu {
	display: none;

	img {
		display: flex;
		width: 40px;
	}

	@include mobile-md-640 {
		display: block;
		position: absolute;
		right: 20px;
		top: 20px;
	}
}
