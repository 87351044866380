$float-vertically: float-vertically 6s ease-in-out infinite;
$float-vertically-10: float-vertically-10 4s ease-in-out infinite;
$float-horizontally: float-horizontally 6s ease-in-out infinite;
$float-square: float-square 12s ease-in-out infinite alternate;

@keyframes float-vertically {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(0rem);
	}
}

@keyframes float-vertically-10 {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0rem);
	}
}

@keyframes float-horizontally {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(-10px);
	}
	100% {
		transform: translateX(0rem);
	}
}

@keyframes float-square {
	0% {
		transform: translate(0, -10px);
	}
	20% {
		transform: translate(-10px, 0);
	}
	40% {
		transform: translate(0, 10px);
	}
	80% {
		transform: translate(10px, 0);
	}
	100% {
		transform: translate(0, -10px);
	}
}

@media (min-width: 1024px) and (max-width: 1920px) {
	html {
		font-size: .83vw;
	}
}

body {
	background: #feffff;
}

.wrap_main {
	overflow: hidden;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url("../img/gradient.png") no-repeat;
		pointer-events: none;
		z-index: -1;
		background-size: cover;
	}
}

section {
	@include tablet-md-1024 {
		margin-bottom: 50px;
	}
}

.container {
	max-width: $containerWidth;
	width: 100%;
	margin: 0 auto;
	@include desktop-md {
		padding-left: 160px;
		padding-right: 160px;
	}
	@include desktop-md {
		padding-left: 0;
		padding-right: 0;
		max-width: 90vw;
	}

	&.d-flex {
		@include desktop-md {
			justify-content: center;
		}
		@include tablet-md-1024 {
			flex-direction: column;
			.image_box {
				width: 100%;
				margin-top: 30px;
			}
		}
	}
}

section {
	&:nth-child(even) {
		@include tablet-md-1024 {
			.d-flex {
				flex-direction: column-reverse;
			}
		}
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.8125rem;
	margin-right: -0.8125rem;
}

.row [class*='col-'] {
	padding-left: 0.8125rem;
	padding-right: 0.8125rem;
}

.row.no_gutter {
	margin-left: 0;
	margin-right: 0;
}

.row.no_gutter [class*='col-'] {
	padding-left: 0;
	padding-right: 0;
}

.col-6 {
	width: 50%;
	@include tablet-md-1024 {
		width: 100%;
	}
}

.col-4 {
	width: 33.3333333%;
	@include tablet-md-1024 {
		width: 50%;
	}
	@include tablet-md-1024 {
		width: 100%;
	}
}

.col-3 {
	width: 25%;
	@include tablet-md-1024 {
		width: 33.33333%;
	}
	@include tablet-md-1024 {
		width: 50%;
	}
	@include mobile-sm-480 {
		width: 100%;
	}
}

.btn {
	border-radius: 1.5625em;
	color: rgb(255, 255, 255);
	font-weight: bold;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 2.1em;
	background: rgb(70, 206, 253);
	background: linear-gradient(90deg, rgba(70, 206, 253, 1) 0%, rgba(4, 206, 195, 1) 100%);
	font-size: 1.5rem;
	transition: .3s;
	white-space: nowrap;

	&:hover {
		opacity: .7 !important;
	}

	@include tablet-md-1024 {
		padding: .5em 1.1em;
	}

	&.btn_big {
		padding: .8em 3.8em;
		@include mobile-md-640 {
			padding: 14px 24px;
		}
	}
}

.title_h1 {
	font-size: 4.59rem;
	color: rgb(0, 0, 0);
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: .1em;
	@include tablet-md-1024 {
		font-size: 3rem;
		margin-bottom: 0.4em;
	}
	@include mobile-md-640 {
		font-size: 40px;
	}
}


.title_h2 {
	font-size: 2.53rem;
	color: rgb(0, 0, 0);
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: .9em;
	@include tablet-md-1024 {
		font-size: 2rem;
		margin-bottom: 15px;
	}
	@include mobile-md-640 {
		font-size: 28px;
	}
}

p {
	font-size: 1.56rem;
	color: #919191;
	line-height: 1.5;
	max-width: 44rem;
	@include tablet-md-1024 {
		font-size: 1.5rem;
		line-height: 1.4;
	}
	@include mobile-md-640 {
		font-size: 19px;
	}
}

.shadow {
	border-radius: 1rem;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 25px 90px 0px rgba(88, 166, 205, 0.56);
}

.image {
	position: relative;

	img {
		border-radius: 1rem;
		width: 100%;
		height: 100%;
		object-fit: cover;
		vertical-align: middle;
	}

	.image_s4_2 {
		position: absolute;
		right: -6%;
		bottom: -10%;
		z-index: -1;
		@include tablet-md-1024 {
			display: none;
		}
	}
}

.d-flex {
	display: flex;

	&.center_v {
		align-items: center;
	}
}

.image_box {
	position: relative;
	@include desktop-md {
		width: 50%;
	}

	.position {
		&.left {
			margin-left: 8rem;
			@include tablet-md-1024 {
				margin: 0;
			}
		}
	}
}

.image_chips {
	position: absolute;
	pointer-events: none;
	@include tablet-md-1024 {
		display: none;
	}

	&.chips_s1_1 {
		bottom: -4%;
		left: -9%;
		z-index: -1;
		width: 18%;
		height: auto;
		animation: $float-square;
	}

	&.chips_s1_2 {
		top: 14%;
		right: -5%;
		z-index: -1;
		width: 7%;
		height: auto;
		animation: $float-vertically-10;
	}

	&.chips_s2_1 {
		top: 41%;
		left: -2%;
		width: 9%;
		height: auto;
		animation: $float-vertically-10;
	}

	&.chips_s2_2 {
		bottom: 6%;
		left: 35%;
		width: 8%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s2_3 {
		bottom: 14%;
		right: 2%;
		width: 12%;
		height: auto;
		animation: $float-square;
	}

	&.chips_s2_4 {
		top: 1%;
		right: -2%;
		width: 7%;
		height: auto;
		animation: $float-horizontally;
	}

	&.chips_s2_5 {
		bottom: 61%;
		left: -8%;
		width: 6%;
		height: auto;
		animation: $float-square;
	}

	&.chips_s2_6 {
		top: -50%;
		right: -2%;
		width: 14%;
		height: auto;
		animation: $float-vertically-10;
	}

	&.chips_s3_1 {
		top: -20%;
		left: -41%;
		animation: $float-vertically-10;
		z-index: -1;
		width: 147%;
		height: auto;
		max-width: none;
	}

	&.chips_s3_2 {
		top: -17%;
		right: 24%;
		width: 11%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s4_1 {
		top: -14%;
		left: 8%;
		z-index: -1;
		width: 19%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s4_2 {
		bottom: -20%;
		left: 46%;
		width: 2%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s4_3 {
		top: -8%;
		left: 11%;
		width: 2%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s4_4 {
		top: 5%;
		right: -1%;
		width: 5%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s4_5 {
		bottom: -82%;
		right: 26%;
		width: 46%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s5_1 {
		bottom: 57%;
		right: 41%;
		width: 5%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s5_2 {
		bottom: -35%;
		right: 6%;
		width: 7%;
		height: auto;
		animation: $float-vertically-10;
	}

	&.chips_s5_3 {
		bottom: 65%;
		right: -5%;
		width: 17%;
		height: auto;
		animation: $float-square;
	}

	&.chips_s5_4 {
		top: -27%;
		right: 18%;
		width: 55%;
		height: auto;
		z-index: -1;
		animation: $float-horizontally;
	}

	&.chips_s6_1 {
		top: -37%;
		right: 3%;
		width: 90%;
		height: auto;
		z-index: -1;
		animation: $float-square;
	}

	&.chips_s6_2 {
		top: -44%;
		left: 13%;
		width: 10%;
		height: auto;
		animation: $float-horizontally
	}

	&.chips_s6_3 {
		bottom: -35%;
		right: 10%;
		width: 9%;
		height: auto;
		animation: $float-vertically-10;
	}

	&.chips_s6_4 {
		top: -30%;
		right: -1%;
		width: 12%;
		height: auto;
		animation: $float-vertically;
	}

	&.chips_s6_5 {
		bottom: -60%;
		left: -2%;
		width: 58%;
		height: auto;
		animation: $float-horizontally;
	}
}

.content_box {
	position: relative;
	@include tablet-md-1024 {
		width: 50%;
	}
	@include tablet-md-1024 {
		width: 100%;
	}

	&.position {
		&.left {
			margin-left: 6rem;
			@include tablet-md-1024 {
				margin: 0;
			}
		}
	}
}

@media (min-width: 1025px) {
	section:not(.section_top) {
		.title_h2 {
			font-size: 2.4rem;
			@include mobile-md-640 {
				font-size: 28px;
			}
		}
	}
}


.social_box {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;

	li {
		margin-right: 3.4rem;
		@include tablet-md-1024 {
			margin: 10px;
		}
		list-style: none;

		&:last-child {
			margin-right: 0;
			@include tablet-md-1024 {
				margin-right: 10px;
			}
		}

		a {
			width: 100%;
			height: auto;
			display: flex;
			transition: .3s;
			max-width: 2.7rem;

			img {
				width: 100%;
			}

			@include tablet-md-1024 {
				width: 40px;
				height: 40px;
			}

			&:hover {
				opacity: .7;
			}
		}
	}
}
